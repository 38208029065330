import React from 'react';
import queryString from 'query-string';
import './App.css';

function App() {
  const params = queryString.parse(window.location.search);

  const { usr, auth, cpf, unidade } = params
  const { REACT_APP_IFRAME_URL } = process.env
  const url = `${REACT_APP_IFRAME_URL}/auth?auth=${auth}&usr=${usr}&cpf=${cpf}&unidade=${unidade}`

  return (
    <div className="App">
      <iframe title="Informed" src={url} ></iframe>
    </div >
  );
}

export default App;
